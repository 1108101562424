<template><div>
	<h3>Batch import associations</h3>

	<p>The batch importing association feature is a flexible way to quickly import associations to display relationships between items within the same framework or between items of two different frameworks. Before you begin to import associations between verify that:
        <ul>
            <li>The frameworks you want to associate are in your Satchel instance.
                <ul>
                    <li>See our User Guide for <span v-html="link('mirrors', 'Mirroring Frameworks')"></span> if needed.</li>
                </ul>
            </li>
            <li>Create a spreadsheet with the first column (optional), containing the association type (e.g. related, precedes, exact match, etc.) between items. The second column will contain the Human Readable Code (or GUID) of items from the origin item in your current framework , and a third column containing the Human Readable Code (or GUID) of the items in your reference framework that you want to associate to (whether the same framework or a different external framework.
                <ul>
                    <li>If the association type column is not provided, the <strong>association type</strong> will default to <strong>Related.</strong></li>
                    <li>To Batch Remove associations, add “-” in front of the association type in the first column.</li>
                    <li>Included is a <a href="https://docs.google.com/spreadsheets/d/1eRxy8Geq_g4Cer1X3F_rDeYIYoRM1DAElbYcZtVGASo/edit?gid=0#gid=0" target="_blank"> Sample Association Spreadsheet </a> for the internal associations in the <a href="https://standards.azed.gov/3a0f77c0-0d6e-4579-b9b4-29a4cbf5483b" target="_blank">  Arizona Computer Science Standards (2018) framework.</a></li>
                </ul>
            </li>
        </ul>
    </p>

    <p>To navigate to the batch import associations option follow the steps below:
        <ol>
            <li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> with a System / Framework Admin or Editor access (see <span v-html="link('admin', 'Admin Functions')"></span>)</li>
            <li>Choose a framework you have edit access to (we will call this the Source framework).</li>
            <li>Enable editing from the framework toolbar, selecting the document card for the framework, and choosing EDIT FRAMEWORK.</li>
            <li>Selecting MORE OPTIONS from the edit framework menu and BATCH IMPORT ASSOCIATIONS FROM TEXT INPUT from the dialog.</li>
        </ol>
    </p>

    <p>To start the batch import of associations:
        <ol class="k-no-clear">
            <img srcset="/docimages/batch_import_assoc.png 2x" class="k-help-img float-right">
            <li>From the top of the <strong>Edit Document</strong> modal, choose the Reference framework.</li>
            <li>Verify that your imported associations should be saved as a Crosswalk (this option will be checked by default and means that both the source and reference frameworks will show the associations).</li>
            <li>Go to your spreadsheet and copy the two to three columns you need as described above and paste them (Control / Command V)  into the text input area. Don’t worry about the spacing.</li>
        </ol>
        Select PROCESS ASSOCIATIONS to preview the HRCs (or GUIDs) and full statements of the associations to be imported, any lines that were <strong>skipped</strong> (no association given) or any lines with <strong>errors</strong> (HRC or GUID not included in one of the frameworks).
        <br>
        <br>
        <img srcset="/docimages/processing_results_log.png 2.5x" class="k-help-img float-right">
        After previewing the Processing Results log either choose CANCEL to start over or ADD ASSOCIATIONS to import and save your work. You will see a SAVE notification in the top left corner of your screen and a prompt Association processing complete.
    </p>
   
    
    <p>
        To view the associations you’ve just imported, remember to enable associations from the framework toolbar and check off the associated frameworks you wish to view. See our <span v-html="link('view_assoc', 'View Associations')"></span> or Associations Table User Guides for more information.
    E-mail <a href="support@commongoodlt.com">support@commongoodlt.com</a>
 for further help.
    </p>












</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>